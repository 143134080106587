import './FlashChat.css';

export default function FlashChat() {
  return (
    <div className="flash-chat-container">
      <span className="flash">FLASH</span>
      <span className="chat">CHAT</span>
    </div>
  )
}
